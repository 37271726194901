<script>
import FormComponent from './../FormComponent';

export default {
	components: {
        FormComponent
    },
    methods: {
        handleInput(e) {
            const output = [ ...e, this.field.id ];
            this.$emit('input', output);
        },
        handleBlur() {
            this.liveValidation();
        }
    },
}
</script>
