import Vue from 'vue';
import Vuex from 'vuex';
import * as types from './types';
import * as datamanager from '../plugins/datamanager';
// import analytics from '../plugins/analytics';

Vue.use(Vuex);

/* eslint-disable no-undef */
const inputLang = locale;
const inputSlug = survey;
/* eslint-enable no-undef */

export default new Vuex.Store({
	state: {
		prevStep: {},
		step: {},
		survey: {},
		loading: false,
		language: inputLang,
		slug: inputSlug,
		dictionary: {}
	},
	getters: {
		[types.GET_TRANSLATION]: (state) => (text) => {
            const translation = state.dictionary[text];
            return translation; 
        }
	},
	mutations: {
		[types.MUTATE_SET_STEP]: (state, step) => {
			state.prevStep = state.step;
			state.step = step;
		},
		[types.MUTATE_SET_LANGUAGE]: (state, language) => {
			state.language = language;
		},
		[types.MUTATE_SET_DICTIONARY]: (state, dictionary) => {
			state.dictionary = dictionary;
		},
		[types.MUTATE_SET_SURVEY]: (state, survey) => {
			state.survey = survey;
		},
		[types.MUTATE_SET_LOADING]: (state, loading) => {
			state.loading = loading;
		}
	},
	actions: {
		[types.SET_STEP]: ({ commit }, step) => {
            // if (step.dataLayer) analytics(step.dataLayer);
			commit(types.MUTATE_SET_STEP, step);
		},
		[types.SET_LANGUAGE]: async ({ commit }, language) => {
			commit(types.MUTATE_SET_LANGUAGE, language);
		},
		[types.SET_DICTIONARY]: async ({ commit }, language) => {
			try {
				const result = await datamanager.getDictionary(language);
				commit(types.MUTATE_SET_DICTIONARY, result.data);
			} catch (error) {
				console.log(error);
			}
		},
		[types.SET_SURVEY]: ({ commit }, survey) => {
			commit(types.MUTATE_SET_SURVEY, survey);
		},
		[types.SET_LOADING]: ({ commit }, loading) => {
			commit(types.MUTATE_SET_LOADING, loading);
		}
	}
});
