<template>
	<field-decorator :field="field" :error="error" :success="success">
		<datetime 
			v-model="value"
			:flow="dateFlow"
			format="dd/MM/yyyy"
			:id="field.id"
			@blur="onBlur"
			@focus="handleFocus"
			v-attributes="field.attributes"
			class="form__control form__control--datepicker"
		>
		</datetime>
	</field-decorator>
</template>

<script>
import { Datetime } from 'vue-datetime';
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'DatepickerComponent',
	mixins: [FormElementMixin],
	computed: {
		dateFlow() {
			if (this.field.flow) return this.field.flow;
			return ['year', 'month', 'date']
		}
	},
	components: {
		Datetime
	}
}
</script>
