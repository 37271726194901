<template>
	<div>
		<p class="message message--alert" 
			v-if="! form.success"
			v-translate
			ref="errors">
			Niet alle velden werden correct ingevuld.<br> Gelieve de ontbrekende gegevens in te vullen.
		</p>
		<div v-for="field in form.controls" :key="field.id" @keyup.enter="submitForm">
			<template v-if="field.type !== 'group'">
				<form-component	
					:field="field"
					@input="handleInput($event)"
                    @prefill="handlePrefill($event)"
					v-show="visible(field.id)"
					>
				</form-component>
			</template>
			<template v-else>
				<form-group
					:field="field"
					@input="handleInput($event)"
					v-show="visible(field.id)"
					>
				</form-group>
			</template>
		</div>

		<div v-if="step.textOkFlag" class="intro" v-html="step.textOk"></div>

		<p>{{ form.errorMessage }}</p>
		<div class="form-buttons form-buttons--flex">
          <a href="#" class="text--small" @click.prevent="prevStep" v-translate v-if="this.previous">Vorige stap</a>
          <button v-if="form.displaySubmit" type="button" class="button" @click="submitForm" v-translate>Volgende stap</button>
		</div>
	</div>
</template>

<script type="module">
import FormComponent from './FormComponent';
import FormGroup from './FormGroup';
import * as types from '../../store/types';
import StatesMixin from './mixins/StatesMixin';
import { mapState } from 'vuex';

export default {
	name: 'Form',
	mixins: [StatesMixin],
	data() {
		return {
			formData: {},
		}
	},
	props: {
		form: {
			type: Object,
			required: true,
			default: () => {},
		},
		previous: {
			type: String,
			required: false,
			default: null
		}
	},
	watch: {
		form() {
			// reset the states back to default because there is a new form
			this.resetStates();
			// process states for the initial state of a field
			this.processAllStates();
			this.setOrderDataLayer();

			this.formData = {};
			this.addDefaultValues();

      this.$router.push({ name: this.$router.currentRoute.name, params: {
					...this.$route.params, 
					step: this.form.values.step,
					candidate: this.form.values.candidate,
					token: this.form.token,
					autonav: true 
				} 
			}).catch(() => {});
		}
	}, 
	computed: {
		...mapState({
			locale: state => state.language,
			slug: state => state.slug,
			step: state => state.step,
            autoFillSelect: state => state.survey.updateOptionsAjaxBaseUrl
		}),
	},
	methods: {
		handleInput(e) {
			this.processStates(e);
			// First check if input is part of a form group, in this case the array "e" has a third element.
			if (e.length > 2) {
				// If the element is contained in a group, find the group and then find the correct element, and update the value.
				// The double arrow function might confuse: the first find returns the group, we select the controls from that group
				// and we execute a find on those controls, which returns the correct element. After that we select the value and
				// update it.
				this.form.controls.find(x => x.id === e[2]).controls.find(y => y.id === e[0]).value = e[1];
				// console.log(e[1]);
			} else {
				// This updates the value in the child components for non-grouped components.
				this.form.controls.find(x => x.id === e[0]).value = e[1];
			}
		},
		scrollToTop() {
			window.scrollTo(0,0);
		},
		generateFormData() {
			let output = {};
			this.form.controls.forEach((element) => {
				output[element.id] = element.value;

				if (element.type === 'group') {
					element.controls.forEach(field => {
						output[field.id] = field.value;
					})
				}
			});
			this.formData = {...this.formData, ...output};
		},
		async submitForm() {

		    if (! this.form.displaySubmit) {
		        return;
            }

			const url = this.form.action;
			this.generateFormData();

			try {
				// Submit the form
				this.$store.dispatch(types.SET_LOADING, true);
				const result = await this.$post(url, this.formData);
                if (result.data.step.confirmation) {

                    this.$analytics({
                        event: 'formStepSubmit',
                        form: {
                            progress: {
                                name: this.step.dataLayer.stepLabel,
                                order: this.step.dataLayer.stepOrderIndex,
                            },
                            step: {
                                name: this.step.dataLayer.subStepLabel,
                                order: this.step.dataLayer.subStepOrderIndex,
                                time: (new Date()).toISOString(),
                            },
                            name: this.step.dataLayer.formTitle,
                            time: (new Date()).toISOString(),
                        }
                    });

                    this.$analytics({
                        event: 'formSubmit',
                        form: this.formDataLayerObject()
                    });
                } else {
                    this.$emit('submit', result.data);
                }

				this.$store.dispatch(types.SET_LOADING, false);
				// Body returned is a new form, set this form in Vuex.
				this.$store.dispatch(types.SET_STEP, result.data.step).then(() => {
					const stepdata = result.data.step;

                    this.setOrderDataLayer();
					// If there is a confirmation node, the form has ended. Push to the confirmation page.
					if (stepdata.confirmation) {
						this.$router.push({ name: 'thanks' });
						return;
					}
				});
				
				this.scrollToTop();
			} catch (error) {
				console.log(error);
			}
		},
		async prevStep() {
			this.$router.push({ name: this.$router.currentRoute.name, params: { 
					...this.$route.params, 
					step: this.previous,
					candidate: this.form.values.candidate,
					token: this.form.token,
					autonav: false 
				} 
			}).catch(() => {});
		},
		addDefaultValues() {
			this.formData = { ...this.form.values };
        },
        async handlePrefill(e) {
            const child = this.form.controls.find(x => x.id === e.child);

            try {
                const result = await this.$post(this.autoFillSelect, e);
                child.options = result.data;
            } catch (error) {
                console.log(error);
            }
        },
		formDataLayerObject() {
			return {
				name: this.step.dataLayer.formTitle,
				time: (new Date()).toISOString(),
			}
		},
		setOrderDataLayer() {
		    let currentIndex = 0;
		    if(this.step.form && this.step.form.controls) {
                this.step.form.controls.forEach(control => {
                    if(control.type !== 'group' ) {
                        control.dataLayerOrder = currentIndex;
                        currentIndex++;
                    } else {
                        if(control.controls) {
                            control.controls.forEach(childControl => {
                                childControl.dataLayerOrder = currentIndex;
                                currentIndex++;
                            })
                        }
                    }
                });
            }
        }
	},
	components: {
		FormComponent,
		FormGroup
	},
	created() {
		this.addDefaultValues();
		this.resetStates();
		this.processAllStates();

		this.setOrderDataLayer();

		// formReady
		this.$analytics({
			event: 'formReady',
			form: this.formDataLayerObject()
		});


	}
}
</script>