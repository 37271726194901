<template>
	<div>
		<progress-bar :progress="step.progress"></progress-bar>
        <section v-if="step.notifications.length" class="notice-wrapper">
            <div v-for="notice in step.notifications" :key="notice"
                 class="notice"
                 :class="'notice--' + notice.level"
            >
                <span class="notice__title" v-if="notice.title" v-html="notice.title"></span>
                <span class="notice__text" v-if="notice.text" v-html="notice.text"></span>
            </div>
        </section>
		<h1 class="title title--line">
            {{step.title}}
            <span v-if="step.titleIntro" v-html="step.titleIntro" class="title__intro"
                  v-bind:class="{ 'title__intro--big' : step.orderIndex === 1}"
            ></span>
        </h1>
		<div v-if="step.textIntroFlag" class="intro" v-html="step.textIntro"></div>
		<usg-form
			v-if="step.form"
			:form="step.form"
			:previous="step.previous"
			@newStep="stepReady"
			@submit="StepSubmit"
		></usg-form>
	</div>
</template>

<script type="module">
import { mapState } from 'vuex';
import Form from '../components/form/Form';
import ProgressBar from '../components/ProgressBar';

export default {
	name: 'Home',
	computed: {
		...mapState({
			step: state => state.step
		})
	},
    watch: {
        step: function (newStep, oldStep) {
            this.stepReady(newStep);
        }
    },
	components: {
		UsgForm: Form,
		ProgressBar
	},
	methods: {
		StepSubmit(data) {

      document.querySelector('[data-auth-message]').innerHTML = data.authMessage;

			if(! data.step.form.success) {

				// formStepError
				this.$analytics({
					event: 'formStepError',
					form: {
						progress: this.progressDataLayerObject(this.step),
						step: {...this.StepDataLayerObject(this.step),
							error: {
						        message: 'Niet alle velden werden correct ingevuld. Gelieve de ontbrekende gegevens in te vullen'
                            }
						},
                        name: this.step.dataLayer.formTitle,
                        time: (new Date()).toISOString(),
					}
				});
			} else {
                // formStepReady
                this.$analytics({
                    event: 'formStepSubmit',
                    form: {
                        progress: this.progressDataLayerObject(this.step),
                        step: this.StepDataLayerObject(this.step),
                        name: this.step.dataLayer.formTitle,
                        time: (new Date()).toISOString(),
                    }
                });
			}
		},
		StepDataLayerObject(step) {
			return {
				name: step.dataLayer.subStepLabel,
				order: step.dataLayer.subStepOrderIndex,
				time: (new Date()).toISOString(),
			}
		},
		progressDataLayerObject(step) {
			return {
				name: step.dataLayer.stepLabel,
				order: step.dataLayer.stepOrderIndex,
			}
		},
		stepReady(step) {
			// formStepReady
			this.$analytics({
				event: 'formStepReady',
				form: {
					progress: this.progressDataLayerObject(step),
					step: this.StepDataLayerObject(step),
                    name: step.dataLayer.formTitle,
                    time: (new Date()).toISOString(),
				}
			});
		}
	},
	created() {
		// pageReady
		this.$analytics({
			event: 'pageReady',
			page: {
				title: this.step.dataLayer.formTitle,
				slug: this.step.dataLayer.formSlug,
				name: this.step.dataLayer.formBackendTitle,
				url: window.location.href,
				language: this.step.dataLayer.language,
				time: (new Date()).toISOString()
			},
			site: {
				name: this.step.dataLayer.formTitle,
				slug: this.step.dataLayer.formSlug,
				hostname: this.step.dataLayer.host,
				brand: this.step.dataLayer.brand
			}
		});

		this.stepReady(this.step);
	}
}
</script>
