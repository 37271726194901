<template>
	<div>
		<field-decorator :field="field">
			<div :id="field.id" class="form__group" :class="'form__group--' + field.role">
				<form-component
					v-for="childField in field.controls"
					:key="childField.id"
					:field="childField"
					:parentField="field"
					class="form__group-item"
                    :class="getRole(childField) ? 'form__group-item--' + getRole(childField): ''"
					@input="handleInput($event)"
                    @blur="checkFieldForBlurAction(childField)">
				</form-component>
			</div>
		</field-decorator>
	</div>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';
import FormGroupMixin from '../mixins/FormGroupMixin';
import FormComponent from '../FormComponent';

export default {
	name: 'IbanBicGroup',
	mixins: [FormElementMixin, FormGroupMixin],
	methods: {
		getRole(control) {
			return control.attributes['data-role'] ? 
			control.attributes['data-role'] : null;
		},
		checkFieldForBlurAction(field) {
			if(this.getRole(field) === 'iban') {
				this.getBIC([field.id, field.value]);
			}
			
			this.handleBlur();
		},
		async getBIC(e) {
			const url = this.field.roleAttributes.url;

			try {
				const result = await this.$get(url, { [this.field.roleAttributes.key]: e[1] });

				// if no bic number is given stop here
				if(!result.data.bic) return;
				
				this.$emit('input', [this.getFieldByRole('iban').id, result.data.iban, this.field.id]);
				this.$emit('input', [this.getFieldByRole('bic').id, result.data.bic, this.field.id]);
				// console.log(result.data.bic);
			} catch (error) {
				console.log(error);
			}
		},
		getFieldByRole(role) {
			return this.field.controls.find(control => this.getRole(control) === role);
		}
	}
}
</script>