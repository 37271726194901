<template>
    <field-decorator class="field-container" :field="field" :error="error" :success="success">
        <div class="form__radios-square">
            <div v-for="option in field.options" :key="option.id" class="form__radio-square">
                <input
                        id="field.id + '-' + option.id"
                        type="radio"
                        :value="option.id"
                        v-model="value"
                        @blur="onBlur"
                        @focus="handleFocus"
                        class="form__control form__control--radio"
                        v-attributes="field.attributes"
                />
                <label :for="field.id + '-' + option.id">{{ option.label }}</label>
            </div>
        </div>
        <div class="form__skills-scale">
            <span class="muted" v-translate>Geen kennis</span>
            <span class="muted" v-translate>Zeer goede kennis</span>
        </div>

    </field-decorator>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';

    export default {
        name: 'SkillsComponent',
        mixins: [FormElementMixin]
    }
</script>
