const baseUrl = process.env.VUE_APP_BASEURL;
const stepUrl = process.env.VUE_APP_STEPURL;
const devUrl = process.env.VUE_APP_DEVURL;
const translationUrl = process.env.VUE_APP_GETTRANSLATION;

// Creates a url with params included, just needs the url and an object with key-value pairs for params.
const generateUrl = (url, params) => {
	let paramsUrl = new URL(url);

	Object.keys(params).forEach(key => {
		if (Array.isArray(params[key])) {
			params[key].forEach(element => paramsUrl.searchParams.append(`${key}[]`, element));
		} else paramsUrl.searchParams.append(key, params[key]);
	});

    return paramsUrl;
}

// Creates the initial URL while checking current environment, this only needs to happen once.
export const initCall = async (step, candidate, token, language, slug) => {
	let url;
	
    if (process.env.NODE_ENV === 'development') {
        url = token === undefined 
            ? `${devUrl}/${language}${baseUrl}${slug}`
            : `${devUrl}/${language}${stepUrl}${step}/${candidate}/${token}`;
    }
    if (process.env.NODE_ENV === 'production') {
        url = token === undefined 
            ? `${language}${baseUrl}${slug}`
			: `${language}${stepUrl}${step}/${candidate}/${token}`;
	}
	
	return await get(url);
}

// Specific call to get dictionary
export const getDictionary = async (language) => {
	let url = `${process.env.VUE_APP_GETTRANSLATION}${language}`;
    if (process.env.NODE_ENV === 'development') url = `${devUrl}${url}`;

    return await get(url);
}

// If a fetch request goes wrong, we end up here.
const errorHandler = async (error) => {
	if (process.env.NODE_ENV === "production") {
		console.log(`Error ${error.status}, contact us!`);
		return;
	}
	console.log(`*** DATA MANAGER ERROR REPORTER ***`);
	console.log(error);
	console.log(await error.json());
	console.log(`*** --- ***`);
}

// FETCH REQUESTS
export const get = async (url, params) => {
    if (params) url = generateUrl(url, params);

	try {
		const request = await fetch(url, {
			method: 'GET'
		});
		if (!request.ok) throw request;
		return await request.json();
	} catch (error) {
		errorHandler(error);
	}
}

export const post = async (url, body, params) => {
	if (params) url = generateUrl(url, params);

	try {
		const request = await fetch(url, {
			method: 'POST',
			body: JSON.stringify(body)
		});
		if (!request.ok) throw request;
		return await request.json();
	} catch (error) {
		errorHandler(error);
	}
}

// VUE PLUGIN
const datamanager = {
	install: Vue => {
		Vue.prototype.$get = (url, params) => get(url, params);
		Vue.prototype.$post = (url, body, params) => post(url, body, params);
        Vue.prototype.$initCall = (step, candidate, token, language, slug) => initCall(step, candidate, token, language, slug);
	}
}

export default datamanager;