<template>
  <field-decorator :field="field" :error="error" :success="success" v-if="visibility">
    <template v-slot="{ fieldIsActive }">

      <v-select
          :options="items"
          @search:blur="blur"
          @search:focus="onSearchFocus(); fieldIsActive();"
          @search="autoComplete($event)"
          :value="displayVal"
          @input="handleInput($event)"
          @focus="handleFocus"
          :placeholder="placeholder"
          ref="select"
      >

        <div slot="spinner" class="loader select__loader" v-show="loading">
        </div>

        <template #selected-option="{ label, value }">
          <div style="display: flex; align-items: baseline;" v-html="label.replace(/<[^>]*>?/gm, '')">
          </div>
        </template>

        <template #option="{ label, value }">
          <div
              class="select__option"
              :class="{ 'select__option--border': lastHighlighted(value)}"
              v-html="label"
          >
          </div>
        </template>

        <div slot="no-options" v-translate>Typ om te zoeken...</div>
      </v-select>
    </template>
  </field-decorator>
</template>

<script type="module">
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'SelectComponent',	
	mixins: [FormElementMixin],
	data() {
		return {
			active: false,
			loading: false,
			items: [],
			displayVal: '',
		}
    },
    computed: {
        visibility() {
            if (this.field.type === 'autocomplete') return true;
            if (this.items.length > 0) return true;
            return false;
        },
        disabled() {
            if (!this.field.disabled) return false;
            else return this.field.disabled;
        },
        placeholder() {
			if(this.active) {
				return this.$translate('Typ om te zoeken');
			}
			return this.field.attributes.placeholder ? this.field.attributes.placeholder: '';
		}
    },
	watch: { 
		"field.options": {
			handler(newVal, oldVal) { // watch it

                this.items = this.field.optionsHighlighted ? [
                    ...this.field.optionsHighlighted.map(x => { return { 'label': x.label, 'value': x.id }}),
                    ...newVal.map(x => { return { 'label': x.label, 'value': x.id }})
                ]: newVal.map(x => { return { 'label': x.label, 'value': x.id }});
                this.fillChildren()
			},
			deep: true
		},
		"field.value": {
			handler(newVal, oldVal) { // watch it
				if(newVal == null) {
					this.displayVal = null;
                    this.$emit('change');
                    this.$emit('blur');
					return;
				}

				this.value = newVal;
				this.displayVal = this.items.find(option => option.value === this.value).label;
			},
			deep: true
		}
	},
	methods: {
		async autoComplete(val) {
			// add higlighting to matching query
			this.items.forEach((option) => {
				// strip all html
				option.label = option.label.replace(/(<([^>]+)>)/gi, "");
				var reg = new RegExp('('+val+')', 'gi');
				// add bold for the matching items
				option.label = option.label.replace(reg, '<b>$1</b>');
			});

			// If we have a static field, don't do a useless call
			if (this.field.type === 'select') return;

			// Set loading to true
			this.loading = true;

			// Make the fetch call using the built-in data manager
			const url = this.autocompleteUrl;
			const params = { 'ref-value': this.field.refValue, 'q': val };
			try {
				const result = await this.$get(url, params);
				// Create an array with { label: x, value: y } objects
				this.items = Object.entries(result.data).map(x => { return { 'label': x[1], 'value': x[0] }});
			} catch (error) {
				console.log(error);
			}

			// Set the loading prop to false
			this.loading = false;
		},
        onSearchFocus(e) {
		    this.active = true;
            this.handleFocus(e);
        },
		handleInput(e) {
			this.active = true;

			if (!e) {
				this.value = null;
				return;
			}
			this.value = e.value;

			// If there are children defined in the field, send calls to prefill them
			this.fillChildren()

			// dirty way of fixing the tabindex but YOLO
			this.$refs.select.$refs.actions.querySelector('.vs__clear').setAttribute('tabindex','-1');
		},
		async resolveId(id) {
			// If field type is not autocomplete, just search the correct value in the options
			if (this.field.type === 'select') {
				this.displayVal = this.field.options.find(x => x.id === this.field.value);
				return;
			}
			
			// Create the object to call for autocomplete
			const params = { 'ref-value': this.field.refValue, 'value': id };
			
			// Make the call, set the value
			try {
				const result = await this.$get(this.autocompleteResolve, params);
				this.displayVal = result.data;
			} catch (error) {
				console.log(error);
			}
		},
		fillChildren() {
            // If we have a children node, we have to prefill the other selects in the children array
            if (!this.field.children) return;

            this.field.children.forEach(element => {
                const prefill = {    
                    parent: this.field.id,
                    child: element,
                    parentValue: this.value
                };
                this.$emit('prefill', prefill);
            })
		},
		blur() {
			this.active = false;
			this.onBlur();
		},
		lastHighlighted(id) {
			return this.field.optionsHighlighted ?
					this.field.optionsHighlighted[this.field.optionsHighlighted.length - 1].id == id :
					false;
		}
	},
	created() {
		// If we're not using autocomplete, load the added items as options.
		if (this.field.type === 'select') {
			this.items = this.field.optionsHighlighted ? [
				...this.field.optionsHighlighted.map(x => { return { 'label': x.label, 'value': x.id }}),
				...this.field.options.map(x => { return { 'label': x.label, 'value': x.id }})
			]: this.field.options.map(x => { return { 'label': x.label, 'value': x.id }});
		}
		if (this.value) this.resolveId(this.value);
	}
}
</script>

