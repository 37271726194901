<template>
	<div>
		<component :is="groupType"
			:field="field" 
			@input="handleInput($event)">
		</component>
	</div>
</template>

<script>
import AddressGroup from './form-groups/AddressGroup';
import IbanBicGroup from './form-groups/IbanBicGroup';
import BaseGroup from './form-groups/BaseGroup';
import YesNoGroup from './form-groups/YesNoGroup';
import LegalCheckGroup from './form-groups/LegalCheckGroup';

export default {
	name: 'FormGroup',
	data() {
		return {
			groupData: {}
		}
	},
	methods: {
		handleInput(e) {
			// Save the group state seperately
			this.groupData[e[0]] = e[1];

			// Emit twice: first set the value of the single field,
			// after that set the value of the group
			this.$emit('input', [ ...e, this.field.id]);
			this.$emit('input', [this.field.id, this.groupData]);
		},
		addDefaultValues() {
			this.field.controls.forEach((element) => {
			    this.groupData[element.id] = element.value;
			});
		}
	},
	computed: {
		groupType() {
			// If there are custom groups, add them to this switch case
			switch(this.field.role) {
				case "address":
					return 'address-group';
				case 'iban-bic':
					return 'iban-bic-group';
				case "yes-no":
				    return 'yes-no-group';
				case "legal-check":
				    return 'legal-check-group';
				default:
					return 'base-group';
			}
		}
	},
	props: {
		field: {
			type: Object,
			required: true,
		}
	},
	components: {
		AddressGroup,
		IbanBicGroup,
		YesNoGroup,
		BaseGroup,
    LegalCheckGroup
	},
	created() {
	    this.addDefaultValues();
	}
}
</script>
