<template>
	<div>
		<div v-if="startedOrEnded === 'start'">
			<div v-html="survey.messageBeforeStart"></div>
			<!-- <p>{{ timeRemaining }}</p> -->
		</div>
		<div v-if="startedOrEnded === 'end'">
			<div v-html="survey.messageAfterEnd"></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'NotAvailable',
	data() {
		return {
			timeRemaining: null,
			countdown: null
		}
	},
	computed: {
		...mapState({
			survey: state => state.survey
		}),
		startedOrEnded() {
			const now = Date.now();
			const start = new Date(this.survey.startDate).getTime();
			const end = new Date(this.survey.endDate).getTime();

			if (now < start) {
				this.startCountdown(start);
				return 'start';
			}
			return 'end';
		}
	},
	methods: {
		startCountdown(start) {
			this.countdown = setInterval(() => {
				let remaining = start - Date.now();
				if (remaining <= 0) this.$router.push({ name: 'home' });

				const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
				const hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const mins = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
				const secs = Math.floor((remaining % (1000 * 60)) / 1000);

				this.timeRemaining = `Nog ${days} dagen, ${hours} uren, ${mins} minuten ${secs} seconden te gaan.`;
			}, 1000);
		}
	},
	destroyed() {
		clearInterval(this.countdown);
	}
}
</script>
