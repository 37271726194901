var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visibility)?_c('field-decorator',{attrs:{"field":_vm.field,"error":_vm.error,"success":_vm.success},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fieldIsActive = ref.fieldIsActive;
return [_c('v-select',{ref:"select",attrs:{"options":_vm.items,"value":_vm.displayVal,"placeholder":_vm.placeholder},on:{"search:blur":_vm.blur,"search:focus":function($event){_vm.onSearchFocus(); fieldIsActive();},"search":function($event){return _vm.autoComplete($event)},"input":function($event){return _vm.handleInput($event)},"focus":_vm.handleFocus},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"},domProps:{"innerHTML":_vm._s(label.replace(/<[^>]*>?/gm, ''))}})]}},{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"select__option",class:{ 'select__option--border': _vm.lastHighlighted(value)},domProps:{"innerHTML":_vm._s(label)}})]}}],null,true)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loader select__loader",attrs:{"slot":"spinner"},slot:"spinner"}),_c('div',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Typ om te zoeken...")])])]}}],null,false,941375337)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }