<template>
    <field-decorator :field="field" :error="error" :success="success">
        <input
                :type="'text'"
                v-model="value"
                :required="field.required"
                :placeholder="field.placeholder ? field.placeholder: '00.00.00-000.00'"
                :id="field.id"
                @blur="onBlur"
                @focus="handleFocus"
                class="form__control"
                v-mask="'##.##.##-###.##'"
                v-attributes="field.attributes"
        />
    </field-decorator>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';

    export default {
        name: 'NationalNumberComponent',
        mixins: [FormElementMixin],
        created() {
            console.log(this.field.type);
        }
    }
</script>