<template>
	<field-decorator :field="field" :error="error" :success="success">
		<span v-for="option in field.options" :key="option.id">
			<label class="form__radio">
				<input
					type="radio"
					:value="option.id"
					v-model="value"
					@blur="onBlur"
					@focus="handleFocus"
					class="form__control form__control--radio"
					v-attributes="field.attributes"
					/>
				<span class="checkmark checkmark--radio"></span>
				{{ option.label }}
			</label>
		</span>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'RadioComponent',
	mixins: [FormElementMixin]
}
</script>
