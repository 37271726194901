<template>
	<div>
		<button class="button" type="button" @click.prevent="handleClick(field.value)">{{ field.label }}</button>
	</div>
</template>

<script>
export default {
	name: 'ButtonComponent',
	methods: {
		handleClick(e) {
			this.$emit('input', e);
		}
	},
	props: {
		field: {
			type: Object,
			required: true,
		}
	}
}
</script>