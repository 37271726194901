// Base Vue imports
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Vue package imports
import VueSlider from 'vue-slider-component';
import vSelect from 'vue-select';
import { Datetime } from 'vue-datetime';
import cssVars from 'css-vars-ponyfill';
import VueMask from 'v-mask'

// Polyfills
import "isomorphic-fetch"; // Fetch support for IE11

// External utils
import datamanager from './plugins/datamanager';
import utils from './plugins/utils';
import polyglot from './plugins/polyglot';
import analytics from './plugins/analytics';

// Importing CSS stuff
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-select/dist/vue-select.css';
import './assets/scss/application.scss';

Vue.config.productionTip = false;
Vue.use(VueMask)
Vue.use(datamanager);
Vue.use(polyglot);
Vue.use(Datetime);
Vue.use(analytics);
Vue.component('v-select', vSelect);
Vue.component('VueSlider', VueSlider)

const opts = {}

// eslint-disable-next-line no-undef
cssVars({
	variables: {
		// eslint-disable-next-line no-undef
		'--color-1': color1,
		// eslint-disable-next-line no-undef
		'--color-2': color2,
		// eslint-disable-next-line no-undef
		'--color-title': color1,
		// eslint-disable-next-line no-undef
		'--logo-path': 'url(' + logoPath + ')',
		// eslint-disable-next-line no-undef
		'--header-image-path': 'url(' + headerImagePath + ')',
	}
});

if(document.getElementById('app')) {
	new Vue({
			router,
			store,
			render: h => h(App),
		data: {
		// eslint-disable-next-line no-undef
		locales: locales,
	}
}).$mount('#app');
}



