<template>
    <field-decorator :field="field" :error="error" :success="success">


        <template v-slot:label="{ showTooltip, toggleWhatIsThis, tooltipVisible, hideTooltip, infoDropdownActive }">
            <div class="field__top">

                <label v-if="field.label" class="form__label" :for="field.id " v-html="field.label"></label>
                <span class="tooltip" v-if="field.helpText">
                    <i
                            class="material-icons icon--info tooltip__icon"
                            :class="{'tooltip__icon--active': tooltipVisible}"
                            v-click-outside="hideTooltip"
                            v-on:click="showTooltip">
                        info
                    </i>
                    <span class="tooltip__info" v-if="tooltipVisible" v-html="field.helpText">
                    </span>
                </span>

                <a
                        href=""
                        v-if="field.whatIsThisText"
                        @click="toggleWhatIsThis"
                        class="field__info-dropdown-trigger"
                        v-translate
                >
                    Wat is dit?
                </a>
            </div>
            <transition name="slide">
                <div class="field__info-dropdown muted" v-if="field.whatIsThisText && infoDropdownActive">
                    {{ field.whatIsThisText }}
                </div>
            </transition>
        </template>
        <div class="form__radios-square">
            <div
                v-for="option in field.options"
                :key="option.id"
                class="form__radio-square form__radio-square--sm"
            >
                <input
                        id="field.id + '-' + option.id"
                        type="radio"
                        :value="option.id"
                        v-model="value"
                        @blur="onBlur"
                        @focus="handleFocus"
                        class="form__control form__control--radio"
                        v-attributes="field.attributes"
                />
                <label :for="field.id + '-' + option.id">{{ option.label }}</label>
            </div>
        </div>

    </field-decorator>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';

    export default {
        name: 'YesNoComponent',
        mixins: [FormElementMixin]
    }
</script>
