var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field__container field-container--",class:[
      {
        'field__container--mandatory': _vm.field.mandatory,
        'field__container--error': _vm.field.mandatory && _vm.error,
        'field__container--success': _vm.field.mandatory && _vm.success,
        'field__container--active': _vm.active,
      }, 'field__container--' + _vm.field.type
    ]},[_vm._t("label",function(){return [_c('div',{staticClass:"field__top",class:'field__top-' + _vm.field.role},[(_vm.field.label)?_c('label',{staticClass:"form__label",class:'form__label-' + _vm.field.role,attrs:{"for":_vm.field.id},domProps:{"innerHTML":_vm._s(_vm.field.label)}}):_vm._e(),(_vm.field.helpText)?_c('span',{staticClass:"tooltip"},[_c('i',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideTooltip),expression:"hideTooltip"}],staticClass:"material-icons icon--info tooltip__icon",class:{'tooltip__icon--active': _vm.tooltipVisible},on:{"click":_vm.showTooltip}},[_vm._v(" info ")]),(_vm.tooltipVisible)?_c('span',{staticClass:"tooltip__info",domProps:{"innerHTML":_vm._s(_vm.field.helpText)}}):_vm._e()]):_vm._e(),(_vm.field.whatIsThisText)?_c('a',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"field__info-dropdown-trigger",attrs:{"href":""},on:{"click":_vm.toggleWhatIsThis}},[_vm._v(" Wat is dit? ")]):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.field.whatIsThisText && _vm.infoDropdownActive)?_c('div',{staticClass:"field__info-dropdown muted"},[_vm._v(" "+_vm._s(_vm.field.whatIsThisText)+" ")]):_vm._e()])]},{"showTooltip":_vm.showTooltip,"toggleWhatIsThis":_vm.toggleWhatIsThis,"tooltipVisible":_vm.tooltipVisible,"hideTooltip":_vm.hideTooltip,"infoDropdownActive":_vm.infoDropdownActive}),_c('div',[_c('div',{staticClass:"input__container",class:"input__container--" + _vm.field.type},[_vm._t("default",null,{"fieldIsActive":_vm.fieldIsActive})],2)]),_vm._t("description",function(){return [(_vm.field.description)?_c('p',{staticClass:"field__description",domProps:{"innerHTML":_vm._s(_vm.field.description)}}):_vm._e()]}),_vm._t("error",function(){return [(_vm.error && _vm.field.errorMessage)?_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(_vm.field.errorMessage))]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }