<template>
	<field-decorator :field="field" :error="error" :success="success">
		<input 
			type="text"
			@keydown="applyMask($event)"
            @keyup="applyMask($event)"
			v-model="value"
			@blur="onBlur"
			@focus="handleFocus"
			v-attributes="field.attributes"
			class="form__control form__control--date-mask"
		>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'DatemaskComponent',
	mixins: [FormElementMixin],
	methods: {
		applyMask(e) {
            let date = e.target.value;
            const lastChar = date.substring(date.length - 1);
            const reg = new RegExp('^[0-9]$');

            // If the typed char is not a number, delete it instantly
            if (!reg.test(lastChar)) date = date.substring(0, date.length - 1);
            
            // If the key hit is not Backspace or Delete, check length of string and add / where necessary
            if (e.key !== 'Backspace' && e.key !== 'Delete' ) {
                if (date.length === 2) date = `${date}/`;
                if (date.length === 5) date = `${date}/`;
            }

            // Always check if / is present in the correct position
            if (date.length > 2 && date.substring(2, 3) !== '/') date = `${date.substring(0, 2)}/${lastChar}`;
            if (date.length > 5 && date.substring(5, 6) !== '/') date = `${date.substring(0, 5)}/${lastChar}`;
            
            // Set the calculated value as value for date field
            this.value = date;
        }
	}
}
</script>

11-11-1988