<template>
	<field-decorator :field="field" :error="error" :success="success">
		<input 
			type="text" 
			:list="field.id" 
			@input="handleInput($event)"
			:value="displayVal"
			:placeholder="field.placeholder"
			v-attributes="field.attributes"
			@blur="onBlur"
			@focus="handleFocus"
			class="form__control form__control--autocomplete autocomplete"
			@keyup.down="arrowSelect('down')" 
			@keyup.up="arrowSelect('up')"
			@keyup.enter.stop="selectedItem"
		/>
		<div class="loader autocomplete__loader" v-if="loading">
		</div>
		<div class="autocomplete__results"
			v-if="Object.keys(items).length && showOptions"
			:id="field.id" >
			<div class="autocomplete__option" 
				v-for="(option, key, i) in items" 
				:class="i === selected ? 'autocomplete__selected' : null" 
				:key="key" 
				:value="option"  
				@mouseover="mouseOver(i)"
				@click="handleInput(key)">
				{{ option }}
			</div>
		</div>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'AutocompleteComponent',
	mixins: [FormElementMixin],
	data() {
		return {
			loading: false,
			items: [],
			search: '',
			displayVal: '',
			showOptions: true,
			timeout: null,
			selected: null
		}
	},
	methods: {
		handleInput(e) {
			let input;
			if (e instanceof InputEvent) {
				input = e.target.value;
				this.displayVal = input;
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.autoComplete(input);
				}, 400); 
				this.$emit('input', input);
			} else {
				input = this.items[e];
				this.displayVal = input;
				this.$emit('input', e);		
				this.showOptions = false;		
			}
		},
		async autoComplete(val) {
			// Set loading to true
			this.loading = true;

			// Make the fetch call using the built-in data manager
			const url = this.autocompleteUrl;
			const params = { 'ref-value': this.field.refValue, 'q': val };
			try {
				const result = await this.$get(url, params);
				this.items = result.data;
				this.showOptions = true;
			} catch (error) {
				console.log(error);
			}

			// Set the loading prop to false
			this.loading = false;
		},
		async resolveId(id) {
			const params = { 'ref-value': this.field.refValue, 'value': id };

			try {
				const result = await this.$get(this.autocompleteResolve, params);
				this.displayVal = result.data.label;
			} catch (error) {
				console.log(error);
			}
		},
		arrowSelect(direction) {
			if (this.selected === null) {
				this.selected = 0;
				return;
			}

			if (direction === 'up' && this.selected > 0) {
				this.selected--;
				return;
			}

			if (direction === 'down' && this.selected < Object.keys(this.items).length -1) {
				this.selected++;
				return;
			}
		},
		selectedItem() {
			const item = Object.keys(this.items)[this.selected];
			this.handleInput(item);
			this.selected = null;
		},
		mouseOver(i) {
			this.selected = i;
		}
	},
	created() {
		if (this.value) this.resolveId(this.value);
	}
}
</script>
