// This component relies on the VUEX store as information source. There needs to be a language object and a
// translations object in there. We retrieve the information using a getter ('GET_TRANSLATION'). The URLS need
// to be set in the .env file.

import store from '../store/index';
import * as types from '../store/types';
import * as dataService from './datamanager';

// Get a single translated text from Vuex
export const text = (untranslated) => {
    untranslated = untranslated.trim();
	const translation = store.getters[types.GET_TRANSLATION](untranslated);
    if (translation === undefined || translation === '') {
        // reportMissing(untranslated);
        return untranslated;
    }
    return translation;
}

// Get plurals - this is not necessary for this project so this just returns single translation.
export const plural = (untranslated) => {
    return text(untranslated);
}

// INTERNAL FUNCTIONS

// If there's a translation missing: add it to the database.
// const reportMissing = async (untranslated) => {
//     const url = dataService.generateUrl(`${process.env.VUE_APP_ADDTRANSLATION}/${store.state.language}`);
//     const body = { string: untranslated };
//     try {
//         await dataService.post(url, JSON.stringify(body));
//     } catch (error) {
//         console.log(error);
//     }
// }

// VUE PLUGIN FUNCTION

// This is the install function for the Vue plugin. This adds the this.$translate / this.$translatePlural functions
// and  also the 'v-translate' and 'v-translate-plural' directives.
const polyglot = {
    install: (Vue) => {
        Vue.prototype.$translate = (untranslated) => text(untranslated);

        Vue.prototype.$translatePlural = (untranslated, untranslatedPlural, count) => {
            return plural(untranslated, untranslatedPlural, count);
        }

        Vue.directive('translate', {
            inserted: (el) => el.textContent = text(el.textContent),
            // update: (el) => el.textContent = text(el.textContent)
        });
        Vue.directive('translate-plural', {
            inserted: (el) => el.textContent = plural(el.textContent),
            // update: (el) => el.textContent = plural(el.textContent)
        });
    }
}

export default polyglot;