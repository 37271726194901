<template>
	<div>
		<!-- <progress-bar :progress="progress" :finished="true"></progress-bar>
		<span v-if="text" v-html="text"></span> -->
	</div>
</template>

<script>
// import ProgressBar from '../components/ProgressBar';
import * as types from '../store/types';
import {mapState} from 'vuex';

export default {
	name: 'Thanks',
	// components: {
	// 	ProgressBar
    // },
    computed: {
        ...mapState({survey:state => state.survey})
    },
    created() {
        // formStepReady
        this.$analytics({
            event: 'formStepReady',
            form: {
                progress: {
                    name: 'Klaar',
                    order: (this.survey.progressStepCount + 1),
                },
                step: {
                    name: 'Bedankt',
                    order: (this.survey.stepCount + 1),
                    time: (new Date()).toISOString(),
                },
            }
        });

        window.location.href = this.survey.thanksUrl;
    }
}
</script>
