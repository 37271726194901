<template>
    <div class="auth0">

        <section class="auto0__options">
            <a v-for="socialField in field.subFieldsSocial" :key="socialField.id" :href="socialField.redirectUrl"
               class="auto0__option"
               @click="startLoader"
            >
                <img :src="'/build/assets/img/' + socialField.id + '.svg'" :alt="socialField.id + ' icon'">
                {{ socialField.title }}
            </a>
        </section>

        <section class="auth0__divider"> 
            {{ field.labelFieldDivider }}
        </section>

        <section v-if="forgotPasswordMessage" class="notice notice--no-margin-bottom">
            {{ forgotPasswordMessage }}
        </section>

        <section class="auth0__email">
            <div v-if="field.subFieldEmail" class="field__container">

                <div class="field__top" :class="'field__top-' + field.subFieldEmail.type">
                    <label v-if="field.subFieldEmail.title" class="form__label"  :class="'form__label-' + field.subFieldEmail.type" :for="field.subFieldEmail.id " v-html="field.subFieldEmail.title"></label>
                </div>

                <input
                    :type="field.subFieldEmail.type"
                    v-model="emailValue"
                    :required="true"
                    :id="field.subFieldEmail.id"
                    class="form__control"
                    @keyup.enter="validateEmail"
                />
                <p v-if="emailError" class="form__error">{{ emailError }}</p>
                <p v-if="emailLoginMessage" class="form-control__description" v-html="emailLoginMessage"></p>
            </div>
        </section>

        <section class="auth0__password">
            <div v-if="field.subFieldPassword && emailValidated" class="field__container">

                <p v-if="! showPasswordDescriptionLogin">{{ field.subFieldPassword.headerRegister }}</p>

                <div class="field__top" :class="'field__top-' + field.subFieldPassword.type">
                    <label v-if="field.subFieldPassword.title" class="form__label"  :class="'form__label-' + field.subFieldPassword.type" :for="field.subFieldPassword.id " v-html="field.subFieldPassword.title"></label>
                </div>

                <input
                    :type="field.subFieldPassword.type"
                    v-model="passwordValue"
                    :required="true"
                    :id="field.subFieldPassword.id"
                    class="form__control"
                    @keyup.enter="validateCredentials"
                />
                <p v-if="showPasswordDescriptionLogin" class="field__description"
                >
                    <a href="#" v-html="field.subFieldPassword.actionPasswordReset.label"
                       @click="forgotPassword"></a>
                </p>
                <p v-else-if=" ! showPasswordDescriptionLogin" class="field__description"
                   v-html="field.subFieldPassword.descriptionRegister">
                </p>
                <p v-if="passwordError" class="form__error">{{ passwordError }}</p>
            </div>
        </section>

        <section class="auth0__firstName" v-if="emailValidated && isEmailRegistered === false">
          <div v-if="field.subFieldFirstName" class="field__container">

            <div class="field__top" :class="'field__top-' + field.subFieldFirstName.type">
              <label v-if="field.subFieldFirstName.title" class="form__label"  :class="'form__label-' + field.subFieldFirstName.type" :for="field.subFieldFirstName.id " v-html="field.subFieldFirstName.title"></label>
            </div>

            <input
                :type="field.subFieldFirstName.type"
                v-model="firstNameValue"
                :required="true"
                :id="field.subFieldFirstName.id"
                class="form__control"
            />

            <p v-if="firstNameError" class="form__error">{{ firstNameError }}</p>
          </div>
        </section>

        <section class="auth0__lastName" v-if="emailValidated && isEmailRegistered === false">
          <div v-if="field.subFieldLastName" class="field__container">

            <div class="field__top" :class="'field__top-' + field.subFieldLastName.type">
              <label v-if="field.subFieldLastName.title" class="form__label"  :class="'form__label-' + field.subFieldLastName.type" :for="field.subFieldLastName.id " v-html="field.subFieldLastName.title"></label>
            </div>

            <input
                :type="field.subFieldLastName.type"
                v-model="lastNameValue"
                :required="true"
                :id="field.subFieldLastName.id"
                class="form__control"
            />

            <p v-if="lastNameError" class="form__error">{{ lastNameError }}</p>
          </div>
        </section>

        <section class="auth0__buttons">
            <button v-if="! emailValidated" type="button" class="button" @click="validateEmail" v-translate>
                Volgende stap
            </button>
            <button v-if="emailValidated" type="button" class="button" @click="validateCredentials" v-translate>
                Volgende stap
            </button>
        </section>
    </div>
</template>

<script>
import * as types from "../../../store/types";
import {mapState} from "vuex";

export default {
    name: 'Auth0Component',
    props: {
        field: {
            type: Object,
            required: true
        },
        error: {
            type: Boolean,
            required: true
        },
        success: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            emailValue: '',
            emailValidated: false,
            emailError: null,
            emailLoginMessage: null,

            isEmailRegistered: null,

            passwordValue: '',
            passwordError: null,
            firstNameError: null,
            lastNameError: null,
            showPasswordDescriptionLogin: false,

            forgotPasswordMessage: null,

            isRedirecting: false
        }
    },
    computed: {
        ...mapState({
            surveyId: state => state.survey.id,
        }),
    },
    methods: {

        startLoader() {
            this.$store.dispatch(types.SET_LOADING, true);
        },

        /**
         * Validate email by posting the value to the backend
         * @returns {Promise<void>}
         */
        async validateEmail() {
            this.$store.dispatch(types.SET_LOADING, true);

            // reset forgotPasswordMessage
            this.forgotPasswordMessage = null;

            const route = this.field.routes.submitEmail;
            const data = {
                'email': this.emailValue,
                'survey': this.surveyId
            };

            const response = await this.post(route, JSON.stringify(data));

            if (response.alreadyLoggedIn && response.redirectUrl) {
                window.location.href = response.redirectUrl;
                return;
            }

            // validate the given email address and set some properties based on the results
            this.emailValidated = this.validateEmailFromResponse(response);
            // Do not turn off the loading state if we're redirecting!
            if ( ! this.isRedirecting) {
                this.$store.dispatch(types.SET_LOADING, false);
            }
        },

        /**
         * validate credentials (email & password) together this time
         */
        async validateCredentials() {
            this.$store.dispatch(types.SET_LOADING, true);

            // reset forgotPasswordMessage
            this.forgotPasswordMessage = null;

            // build the call
            const route = this.field.routes.submitCredentials;
            const data = {
                'email': this.emailValue,
                'password': this.passwordValue,
                'firstName': this.firstNameValue,
                'lastName': this.lastNameValue,
                'survey': this.surveyId
            };
            // make the call
            const response = await this.post(route, JSON.stringify(data));

            if (response.alreadyLoggedIn && response.redirectUrl) {
                window.location.href = response.redirectUrl;
                return;
            }

            // validate the email again
            this.validateEmailFromResponse(response);

            // validate the password
            const validated = this.validatePasswordFromResponse(response);
            if (! validated) {
                // if the password cannot be validated stop the method here
                return;
            }

            // if the password is ok, remove its error
            this.passwordError = null;

            window.location.href = response.redirectUrl;
        },

        /**
         * Validate the email and set its fields based on a response
         * @param response
         * @returns {boolean}
         */
        validateEmailFromResponse(response) {

            // if the email is not valid, show error
            if (! response.isEmailValid) {
                this.emailError = response.emailErrorMessage;
                this.emailLoginMessage = null;
                this.$store.dispatch(types.SET_LOADING, false);
                return false;
            }

            this.isEmailRegistered = response.isEmailRegistered;
            this.firstNameError = response.firstNameErrorMessage;
            this.lastNameError = response.lastNameErrorMessage;

            // if the email is registered:
            if (response.isEmailRegistered) {
                if (response.redirectUrl) {
                    // Mark that we're redirecting, so that the loader will not be turned off
                    this.isRedirecting = true;
                    // We're loading, since we need to redirect...
                    this.$store.dispatch(types.SET_LOADING, true);
                    window.location.href = response.redirectUrl;
                    // Don't do anything else, or we'd display unnecessary elements to the user.
                    return true;
                }
                this.showPasswordDescriptionLogin = true;
                this.emailLoginMessage = this.field.subFieldEmail.messageLoginSuccess;
                this.emailValidated = true;
                this.$store.dispatch(types.SET_LOADING, false);
                return true;
            }

            // if we got here this means that the email is not registered so we set showPasswordDescriptionLogin to false
            this.showPasswordDescriptionLogin = false;
            // there are no errors to show if we got here
            this.emailError = null;
            this.emailLoginMessage = null;

            return true;
        },

        /**
         * Validate the password and set its fields based on a response
         * @param response
         * @returns {boolean}
         */
        validatePasswordFromResponse(response) {

            // check if password is ok
            if (! response.isPasswordValid) {
                this.passwordError = response.passwordErrorMessage;
                this.$store.dispatch(types.SET_LOADING, false);
                return false;
            }

            return true;
        },

        /**
         * Make a call to send an email to the person trying to log in to reset their password
         */
        async forgotPassword(e) {
            e.preventDefault();
            this.$store.dispatch(types.SET_LOADING, true);

            const data = {
                'email': this.emailValue
            };
            const response = await this.post(this.field.subFieldPassword.actionPasswordReset.url, JSON.stringify(data));

            if (response.success && response.message) {
                this.forgotPasswordMessage = response.message;
            }

            this.$store.dispatch(types.SET_LOADING, false);
        },

        /**
         * Quick post method
         * @param route
         * @param data
         * @returns {Promise<any>}
         */
        async post(route, data) {
            const promise = await fetch(route, {
                method: 'POST',
                body: data
            });
            return await promise.json();
        },

        /**
         * Quick get method
         * @param route
         * @returns {Promise<any>}
         */
        async get(route) {
            const promise = await fetch(route, {
                method: 'GET'
            });
            return await promise.json();
        },
    }
}
</script>