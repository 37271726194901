<template>
    <div>
        <field-decorator :field="field" :error="error">
            <div :id="field.id" class="form__group" :class="'form__group--' + field.role">
                <form-component
                        v-for="childField in field.controls"
                        :key="childField.id"
                        :field="childField"
                        :parentField="field"
                        class="form__group-item"
                        @input="handleInput($event)"
                        @blur="handleBlur()"
                        v-show="showField(childField.id)"
                >
                </form-component>
            </div>
        </field-decorator>
    </div>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';
    import FormGroupMixin from '../mixins/FormGroupMixin';

    export default {
        name: 'YesNoGroup',
        mixins: [FormElementMixin, FormGroupMixin],
        methods: {
            checkYesValue: function() {
                return (this.field.controls[0].value === 1);
            },
            showField: function(fieldId) {
                if(fieldId !== this.field.controls[1].id) {
                    return true;
                }

                return this.checkYesValue();
            },
            handleInput(e) {
                const output = [ ...e, this.field.id ];
                this.$emit('input', output);
            },
        }
    }
</script>