import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NotAvailable from '../views/NotAvailable.vue';
import Thanks from '../views/Thanks.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:client/home',
        redirect: '/:client'
    },  
	{
		path: '/thanks',
		name: 'thanks',
		component: Thanks
	},
	{
		path: '/:step?/:candidate?/:token?',
		name: 'form',
		component: Home
	},
	{
		path: '/',
		name: 'home',
		component: Home
	},
    {
		path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
	mode: 'history',
	// eslint-disable-next-line no-undef
	base:  basePath,
	routes, 
	scrollBehavior () {
		return { x: 0, y: 0 }
	},
})

export default router;
