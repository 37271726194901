<template>
	<div>
		<field-decorator :field="field" :error="error">
			<div :id="field.id" class="form__group" :class="'form__group--' + field.role">
				<form-component
					v-for="childField in field.controls"
					:key="childField.id"
					:field="childField"
					:parentField="field"
					class="form__group-item"
					@input="handleInput($event)"
                    @blur="handleBlur()">
				</form-component>
			</div>
		</field-decorator>
	</div>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';
    import FormGroupMixin from '../mixins/FormGroupMixin';

    export default {
        name: 'BaseGroup',	
        mixins: [FormElementMixin, FormGroupMixin],
    }
</script>