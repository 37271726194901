<template>
  <field-decorator :field="field" :error="error" :success="success" class="legal-checks--no-input">
  </field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
  name: 'InfoComponent',
  mixins: [FormElementMixin]
}
</script>