<script>
export default {
	data() {
		return {
			actions: {
				visibility: {},
				mandatory: {}
			}
		}
	},
	methods: {
		processAllStates() {
			this.form.states.forEach(element => {
				const elementToCheck = this.form.controls.find(control => control.id === element.conditions[0]);
				this.processStates([elementToCheck.id,elementToCheck.value]);
			});
		},
		processStates(val) {
			this.form.states.forEach(element => {
				// Start out from an empty array
				let stateValid = [];

				// Check if state is applicable to the changed value
				if (element.conditions[0] !== val[0]) return;
				
				// Check if state is true or false, if val is an array, check if condition is included.
				if (Array.isArray(val[1])) {
					stateValid.push(val[1].includes(element.conditions[1]));
				}
				else stateValid.push(element.conditions[1] === val[1]);
				
				// If there are no evaluated conditions - return (should never happen)
				if (stateValid.length === 0) return;

				// Check if every state is valid
				let isStateValid = !stateValid.includes(false);
				// If there is a "NOT" state, reverse the valid state.
				if (element.conditions.length > 2) {
					isStateValid = !isStateValid;
				}
				
				// If we end up with TRUE, add action to the actions array
				if (isStateValid) {
					if (element.actions.visibility) this.actions.visibility = {...this.actions.visibility, ...element.actions.visibility };
					if (element.actions.mandatory) this.actions.mandatory = { ...this.actions.mandatory, ...element.actions.mandatory };
					this.setMandatory(val);
				}
			});
		},
		resetStates() {
			this.actions.visibility = {};
			this.actions.mandatory = {};
		},
		visible(id) {
			let output = Object.entries(this.actions.visibility).find(x => x[0] === id);
			if (output) output = output[1];
			else return true;
			return output;
		},
		setMandatory(e) {
			const id = e[2];
            // Set the mandatory property for every form control
			for (const key in this.actions.mandatory) {
                // If the e array has more than 2 elements, the third element is the id of the group, so we need to search 1 level deeper.
				if (e.length > 2) {
                    // Find the correct group
                    const group = this.form.controls.find(control => control.id === id);
                    // If no group found, stop the current loop and continue.
                    if (!group) continue;
                    // Find the control within the group
                    const control = group.controls.find(y => y.id === key);
                    // If no control found, stop the current loop and continue.
                    if (!control) continue;
                    // If we find the correct control, set the mandatory property.
                    control.mandatory = this.actions.mandatory[key];
                }
				// If the length is just 2 we're working with a top level formcontrol and set the control accordingly.
                else {
                    // Find the control
                    const control = this.form.controls.find(control => control.id === key);
                    // In no control found, stop the current loop and continue.
                    if (!control) continue;
                    // Set the value.
                    control.mandatory = this.actions.mandatory[key];
                }
			}
		}
	},
}
</script>
