const triggerAnalytics = (info) => {
    //eslint-disable-next-line
    if(typeof dataLayer !== 'undefined') {
        // eslint-disable-next-line
        dataLayer.push(info);
    }
};

// VUE PLUGIN
const analytics = {
    install: Vue => {
        Vue.prototype.$analytics = (info) => triggerAnalytics(info);
    }
};

export default analytics;