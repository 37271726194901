<template>
	<div :key="field.id">
		<component
			:is="fieldType"
			:field="field"
			@input="handleInput($event)"
			@change="handleChange($event)"
			@error="handleError"
			@focus="handleFocus"
			@blur="handleBlur()"
            @prefill="handlePrefill($event)"
		>
		</component>
	</div>
</template>

<script>
import ButtonComponent from './form-elements/ButtonComponent';
import InputComponent from './form-elements/InputComponent';
import SelectComponent from './form-elements/SelectComponent';
import TextAreaComponent from './form-elements/TextAreaComponent';
import DatepickerComponent from './form-elements/DatepickerComponent';
import RadioComponent from './form-elements/RadioComponent';
import CheckboxComponent from './form-elements/CheckboxComponent';
import CheckboxesComponent from './form-elements/CheckboxesComponent';
import SliderComponent from './form-elements/SliderComponent';
import AutocompleteComponent from './form-elements/AutocompleteComponent';
import DatemaskComponent from './form-elements/DatemaskComponent';
import GroupedButtonsComponent from './form-elements/GroupedButtonsComponent';
import YesNoComponent from './form-elements/YesNoComponent';
import WeekPickerComponent from './form-elements/WeekPickerComponent';
import NationalNumberComponent from './form-elements/NationalNumberComponent';
import LegalCheckboxes from './form-elements/LegalCheckboxes';
import InfoComponent from './form-elements/InfoComponent';
import Auth0Component from './form-elements/Auth0Component';


// This component is a wrapper for the individuel form components, it passes data and renders the correct component.
export default {
	name: 'FormComponent',
	props: {
		field: {
			type: Object,
			required: true
		},
        parentField: {
            type: Object,
            required: false
        }
	},
	computed: {
		fieldType() {
			switch(this.field.type) {
				case "button":
					return 'button-component';
				case "select":
					return 'select-component';
				case "datepicker":
					return 'datepicker-component';
				case "radio":
					return 'radio-component';
				case "yesno":
					return 'yes-no-component';
				case "checkbox":
					return 'checkbox-component';
				case "textarea":
					return 'text-area-component';
				case "checkboxes":
					return 'checkboxes-component';
				case "slider":
					return 'slider-component';
				case "grouped-buttons":
					return 'grouped-buttons-component';
				case "autocomplete":
					// return 'autocomplete-component';
					return 'select-component';
				case "datemask":
					return 'datemask-component';
				case "weekpicker":
					return 'week-picker-component';
				case "national-number":
					return 'national-number-component';
				case "legal-checks":
					return 'legal-checks';
				case "info":
					return 'info-component';
				case "auth0":
					return 'auth-0-component';
				default:
					return 'input-component';
			}
		}
	},
	methods: {
		handleInput(e) {
			const output = [ this.field.id, e ];

			this.$emit('input', output);
		},
        handleChange() {
		    if(this.field.value !== null && !this.field.errorMessage) {
                this.$analytics({
                    event: 'formFieldEdit',
                    form: {
                        field: this.fieldDataLayerObject()
                    }
                });
            }
        },
		handleBlur() {
			this.$emit('blur');
        },
        handlePrefill(e) {
            this.$emit('prefill', e);
        },
		handleError() {
			this.$analytics({
				event: 'formFieldError',
                form: {
                    field: {
                        ...this.fieldDataLayerObject(),
                        error: {
                            message: this.field.errorMessage
                        }
                    }
                }
			});
		},
		handleFocus() {
			this.$analytics({
				event: 'formFieldFocus',
                form: {
                    field: this.fieldDataLayerObject()
                }
			});
		},
		fieldDataLayerObject() {
			return {
				name: this.field.attributes.titleDataLayer,
				order: this.field.dataLayerOrder,
				time: (new Date()).toISOString()
			}
		}
	},
	components: {
		ButtonComponent,
		InputComponent,
		SelectComponent,
		TextAreaComponent,
		DatepickerComponent,
		RadioComponent,
		CheckboxComponent,
		CheckboxesComponent,
		SliderComponent,
		AutocompleteComponent,
		DatemaskComponent,
		GroupedButtonsComponent,
		YesNoComponent,
		WeekPickerComponent,
		NationalNumberComponent,
		LegalCheckboxes,
		InfoComponent,
        Auth0Component
	}
}
</script>