<template>
	<div>
		<field-decorator :field="field">
			<div :id="field.id" class="form__group" :class="'form__group--' + field.role">
				<form-component
					v-for="(childField, i) in field.controls"
					v-show="visibleFields.includes(i)"
					:key="childField.id"
					:field="childField"
                    :parentField="field"
					class="form__group-item"
                    :class="getRole(childField) ? 'form__group-item--' + getRole(childField): ''"
					@input="handleInput($event); createAddressGroupFields()"
                    @blur="handleBlur()">
				</form-component>
			</div>
		</field-decorator>
	</div>
</template>

<script>
	import FormElementMixin from '../mixins/FormElementMixin';
	import FormGroupMixin from '../mixins/FormGroupMixin.vue';
    import FormComponent from './../FormComponent';

    export default {
        name: 'AddressGroup',	
        mixins: [FormElementMixin, FormGroupMixin],
        data() {
            return {
				autocomplete: null,
				visibleFields: [0, 1, 2, 3, 4, 5, 6]
            }
        },
        mounted() {
			// Google Maps API integration
			/* eslint-disable no-undef */
            this.autocomplete = new google.maps.places.Autocomplete(
                (this.$el.querySelector('[data-role="addr-street-name"]')),
                {
                    types: ['address'],
                    fields: [
                        'place_id', 'name', 'address_components'
                    ],
                    // BE = prio
                    bounds: new google.maps.LatLngBounds(
                        new google.maps.LatLng(49.516667,2.566667),
                        new google.maps.LatLng(51.683333,6.4)
                    )
                }
			);
			/* eslint-enable no-undef */

            this.autocomplete.addListener('place_changed', this.placePicked);

            // Dont submit on enter but trigger place changed
            this.$el.querySelector('[data-role="addr-street-name"]').addEventListener('keyup',this.checkTriggerPlaceChanged);
			
			this.createAddressGroupFields();
        },
        methods: {
            checkTriggerPlaceChanged(e) {
                e.stopPropagation();
                if (e.which == 13) {
			        /* eslint-disable no-undef */
                    google.maps.event.trigger(this.autocomplete, 'place_changed');
                    return false;
                }
            },
            getRole(control) {
                return control.attributes['data-role'] ? 
                    control.attributes['data-role'] : null;
            },
            placePicked() {
                let place = this.autocomplete.getPlace();
                
                let address = {};
                let mapping = {
                    sublocality_level_1: 'short_name',
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    country: 'short_name',
                    postal_code: 'short_name'
                };
                
                 // create an address array
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (mapping[addressType]) {
                        address[addressType] = place.address_components[i][mapping[addressType]];
                    }
                }
                console.log(place,address);
                this.setFieldValues(address);
            },
            setFieldValues(address) {
                Object.entries(this.field.controls).forEach(([key, field]) => {
                    switch(this.getRole(field)) {
                        case 'addr-street-name': 
                            field.value = address['route'];
                            break;
                        case 'addr-street-number': 
                            field.value = address['street_number'];
                            break;
                        case 'addr-country': 
                            field.value = address['country'];
                            break;
                        case 'addr-city-ifo': 
                            this.setRefValue(field,address['locality']);
                            break;
                        case 'addr-postal-code': 
                            field.value = address['postal_code'];
                            break;
                        case 'addr-city-title': 
                            field.value = address['locality'];
                            break;
                    }
                    this.$emit('input', [field.id,field.value]);
                });
            },
            setRefValue(field,val) {

                // Make the fetch call using the built-in data manager
                const url = this.autocompleteUrl;
                
                const params = { 'ref-value': field.refValue, 'q': val };
                try {
                    this.$get(url, params).then((result) => {
                        // Create an array with { label: x, value: y } objects
                        let items = Object.entries(result.data).map(x => { return { 'label': x[1], 'id': x[0] }});
                        field.options = items;
                        field.value = items[0].id;
                    });
                    
                } catch (error) {
                    console.log(error);
                }
            },
			createAddressGroupFields() {
				const isBE = this.field.controls.find(x => x.refValue === "country").value === "BE";
				if (isBE) {
					this.visibleFields = [0, 1, 2, 3, 4];
				} else {
					this.visibleFields = [0, 1, 2, 3, 5, 6];
				}
			}
		}
    }
</script>