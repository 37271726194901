
<template >
	<div>
        <ul class="progress-bar list list--horizontal d-flex align-items-center" >
            <li class="progress__item list__item list__item--horizontal" v-for="(step, id) in progress" :key="id">
                <div @click="showTitle(id)" class="progress__item-label" v-click-outside="hideTitle">
                    <span class="progress__link"
                        :class="{ 'progress__link--active': step.isActive, 'progress__link--completed': step.isCompleted || finished}">
                        {{ step.number }}
                    </span>
                    <span :class="{'progress__item-title--visible': titleVisible(id)}" class="progress__item-title">
                        {{ step.title }}
                    </span>
                </div>
                <div class="progress__item-bar" v-if="step.percent">
                    <span v-if="!finished" class="progress__item-bar__progress"  :style="'width:' + step.percent"></span>
                    <span v-else class="progress__item-bar__progress"  :style="'width: 100%'"></span>
                </div>
            </li>
        </ul>
	</div>
</template>

<script>

export default {
    name: 'ProgressBar',
    props: {
        progress: Array,
        finished: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visibleTitle: null,
        }
    },
    methods: {
        showTitle(id) {
            this.visibleTitle = id;     
        },
        hasSomeParentTheClass(element, classname){
            if (element.className && typeof element.className === 'string'&& element.className.split(' ').indexOf(classname)>=0) return true;
            return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
        },
        hideTitle(e) {
            if(this.hasSomeParentTheClass(e.target,'progress__item')) return;
            this.visibleTitle = null;     
        },
        titleVisible(id) {
            return this.visibleTitle == id;
        }
    },
}
</script>
