<template>
	<field-decorator :field="field" :error="error" :success="success">
		<template slot="label"><span></span></template>
		
			<label v-if="field.label" class="form__label" :for="field.id " v-html="field.label"></label>
			<vue-slider
				v-model="value"
				:data="ticksLabels"
				:marks="true"
				:useKeyboard="true"
				:contained="true"
				:tooltip="'none'"
				@focus="handleFocus"
				@blur="onBlur"
			></vue-slider>
	</field-decorator>
</template>

<script type="module">
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'SliderComponent',
	mixins: [FormElementMixin],
    data: () => {
	    return {
            valueAlreadySetForFirstTime: false
        }
    },
	computed: {
		ticksLabels() {
			return this.field.options.map(x => x.label);
		},
		value: {
			get() {
				return this.field.options.findIndex(x => x === this.field.value);
			},
			set(val) {
				let sliderVal;

				this.field.options.forEach(function(item,key){
					if(item.label == val) {
						sliderVal = item.id;
					}
				});

				this.$emit('input', sliderVal);

				if(this.valueAlreadySetForFirstTime) {
                    this.$emit('change', sliderVal);
                }
				this.valueAlreadySetForFirstTime = true;
			}
		}
	},
	created() {
		this.value = this.field.options[0].label;
	}
}
</script>
