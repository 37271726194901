<template>
	<div class="app">
		<div class="loader loader--big loader--overlay" v-if="$store.state.loading && $store.state.survey">
			<img class="loader__logo" :src="$store.state.survey.logoPath? $store.state.survey.logoPath : logoPath" :alt="$store.state.survey.title">
		</div>
		<transition name="rotate-in" appear>
			<main class="panel panel--up" v-if="$store.state.step.form">
				<router-view></router-view>
			</main>
		</transition>
	</div>
</template>

<script>
import * as types from './store/types';
import { mapState } from 'vuex';

export default {
	name: 'App',
	data() {
		return {
			// eslint-disable-next-line no-undef
			logoPath: logoPath,
			// eslint-disable-next-line no-undef
			headerTitle: headerTitle,
		}
	},
	computed: {
		...mapState({
			locale: state => state.language,
			slug: state => state.slug
		})
	},
	methods: {
		async getInitData(step, candidate, token, locale, slug) {
			// Get initial data
			const result = await this.$initCall(step, candidate, token, locale, slug);

      document.querySelector('[data-auth-message]').innerHTML = result.data.authMessage;

			// Send necessary data to Vuex
			this.$store.dispatch(types.SET_SURVEY, result.data.survey);
			this.$store.dispatch(types.SET_STEP, result.data.step);
			if (result.data.survey) {
				this.$store.dispatch(types.SET_LOADING, false);
			}
			// Set custom title
			document.title = result.data.survey.pageTitle;
			document.querySelector('meta[name=description]').setAttribute('content',result.data.survey.pageDescription);
        }
	},
	mounted() {
		const params = this.$route.params;

		this.$store.dispatch(types.SET_DICTIONARY, this.locale).then(() => {
			if (params.step && params.candidate && params.token) {
				this.getInitData(params.step, params.candidate, params.token, this.locale, this.slug);
			} else this.getInitData(undefined, undefined, undefined, this.locale, this.slug);
		})
	},
	watch: {
		$route(to) {
			const toParams = to.params;
			if (!toParams.autonav) {
				this.$store.dispatch(types.SET_LOADING, true);
				this.getInitData(toParams.step, toParams.candidate, toParams.token, this.locale, this.slug).then(() => {
					this.$store.dispatch(types.SET_LOADING, false);
				});
			}
		}
	},
	created () {
		this.$store.dispatch(types.SET_LOADING, true);
	}
};
</script>