// GETTERS
export const GET_TRANSLATION = 'GET_TRANSLATION';

// MUTATIONS
export const MUTATE_SET_LANGUAGE = 'MUTATE_SET_LANGUAGE';
export const MUTATE_SET_STEP = 'MUTATE_SET_STEP';
export const MUTATE_SET_DICTIONARY = 'MUTATE_SET_DICTIONARY';
export const MUTATE_SET_SURVEY = 'MUTATE_SET_SURVEY';
export const MUTATE_SET_LOADING = 'MUTATE_SET_LOADING';

// ACTIONS
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_STEP = 'SET_STEP';
export const SET_DICTIONARY = 'SET_DICTIONARY';
export const SET_SURVEY = 'SET_SURVEY';
export const SET_LOADING= 'SET_LOADING';
export const INIT_CALL = 'INIT_CALL';