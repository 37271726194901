<template>
	<field-decorator :field="field" :error="error" :success="success">
		<div v-for="option in field.options" :key="option.id" :id="field.id" @blur="onBlur">
			<label class="form__checkbox" :for="option.id">
				<input
					type="checkbox"
					:value="option.id"
					:id="option.id"
					class="form__control form__control--checkbox"
					v-attributes="field.attributes"
					v-model="value"
					@blur="onBlur"
					@focus="handleFocus"
					/>
				<span class="checkmark checkmark--checkbox"></span>
				<span v-html="option.label"></span>
			</label>
		</div>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'CheckboxesComponent',
	mixins: [FormElementMixin],
	computed: {
		value: {
			get() {
				if (this.field.value === null) return [];
				return this.field.value;
			},
			set(val) {
				this.$emit('input', val);
				// this.$emit('change', val);
			}
		}
	},
	created() {
		if (!Array.isArray(this.value)) this.value = [];
	}
}
</script>
