<template>
	<field-decorator :field="field" :error="error" :success="success">
		<template v-slot:label="{ showTooltip, toggleWhatIsThis, tooltipVisible, hideTooltip, infoDropdownActive }">
			<div class="field__top" v-if="field.helpText || field.whatIsThisText">
                <span class="tooltip" v-if="field.helpText">
                    <i
							class="material-icons icon--info tooltip__icon"
							:class="{'tooltip__icon--active': tooltipVisible}"
							v-click-outside="hideTooltip"
							v-on:click="showTooltip">
                        info
                    </i>
                    <span class="tooltip__info" v-if="tooltipVisible" v-html="field.helpText">
                    </span>
                </span>

				<a
						href=""
						v-if="field.whatIsThisText"
						@click="toggleWhatIsThis"
						class="field__info-dropdown-trigger"
						v-translate
				>
					Wat is dit?
				</a>
			</div>
			<transition name="slide">
				<div class="field__info-dropdown muted" v-if="field.whatIsThisText && infoDropdownActive">
					{{ field.whatIsThisText }}
				</div>
			</transition>
		</template>
		<label class="form__checkbox" :for="field.id">
			<input
				type="checkbox"
				:id="field.id"
				v-model="value"
				@blur="onBlur"
				@focus="handleFocus"
				v-attributes="field.attributes"
				class="form__control form__control--checkbox"
			/>
			<span class="checkmark checkmark--checkbox"></span>
			<span class="form__checkbox-label" v-html="field.label"></span>
		</label>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'CheckboxComponent',
	mixins: [FormElementMixin],
}
</script>
