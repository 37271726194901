<template>
	<field-decorator :field="field" :error="error" :success="success">
		<input 
			:type="field.type"
			v-model="value" 
			:required="field.required"
			:placeholder="field.placeholder"
			:id="field.id"
			@blur="onBlur"
			@focus="handleFocus"
			class="form__control"
			v-attributes="field.attributes"
		/>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'InputComponent',
	mixins: [FormElementMixin]
}
</script>