<template>
	<field-decorator :field="field" :error="error" :success="success">
		<textarea 
			:id="field.id" 
			v-model="value" 
			@blur="onBlur"
			@focus="handleFocus"
			v-attributes="field.attributes"
			class="form__control form__control--textarea"
			@keypress.enter.stop=""
			@keydown.enter.stop=""
			@keyup.enter.stop="">
		</textarea>
	</field-decorator>
</template>

<script>
import FormElementMixin from '../mixins/FormElementMixin';

export default {
	name: 'TextAreaComponent',
	mixins: [FormElementMixin],
}
</script>