<template>
    <div 
        class="field__container field-container--"
        :class="
        [
          {
            'field__container--mandatory': field.mandatory,
            'field__container--error': field.mandatory && error,
            'field__container--success': field.mandatory && success,
            'field__container--active': active,
          }, 'field__container--' + field.type
        ]
    ">
        <slot
            name="label"
            v-bind:showTooltip="showTooltip"
            v-bind:toggleWhatIsThis="toggleWhatIsThis"
            v-bind:tooltipVisible="tooltipVisible"
            v-bind:hideTooltip="hideTooltip"
            v-bind:infoDropdownActive="infoDropdownActive"
        >
            <div class="field__top" :class="'field__top-' + field.role">
                <label v-if="field.label" class="form__label"  :class="'form__label-' + field.role" :for="field.id " v-html="field.label"></label>

                <span class="tooltip" v-if="field.helpText"> 
                    <i 
                        class="material-icons icon--info tooltip__icon" 
                        :class="{'tooltip__icon--active': tooltipVisible}" 
                        v-click-outside="hideTooltip"
                        v-on:click="showTooltip">
                        info
                    </i>
                    <span class="tooltip__info" v-if="tooltipVisible" v-html="field.helpText">
                    </span>
                </span>

                <a
                    href=""
                    v-if="field.whatIsThisText"
                    @click="toggleWhatIsThis"
                    class="field__info-dropdown-trigger"
                    v-translate
                >
                    Wat is dit?
                </a>
            </div>
            <transition name="slide">
                <div class="field__info-dropdown muted" v-if="field.whatIsThisText && infoDropdownActive">
                    {{ field.whatIsThisText }}
                </div>
            </transition>
        </slot>
        
        <div>
          <div class="input__container" :class='"input__container--" + field.type'>
            <slot
                v-bind:fieldIsActive="fieldIsActive"
            />
          </div>
        </div>
        
        <slot name="description">
            <p class="field__description" v-if="field.description" v-html="field.description"></p>
        </slot>
        <slot name="error">
            <p class="form__error" v-if="error && field.errorMessage">{{ field.errorMessage }}</p>
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            field: Object,
            error: Boolean,
            success: Boolean,
        },
        data(){
            return {
                infoDropdownActive: false,
                tooltipVisible: false,
                active: false,
            };
        },
        name: 'FieldDecorator',
        methods: {
            showTooltip(e) {
              e.preventDefault;
                this.tooltipVisible = true;
            },
            hideTooltip(e) {
                e.preventDefault;
                this.tooltipVisible = false;
            },
            toggleWhatIsThis(e) {
                e.preventDefault();
                this.infoDropdownActive = !this.infoDropdownActive;
            },
            fieldIsActive() {
              this.active = !this.active;
            }
        }
    };
</script>
