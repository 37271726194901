<template>
    <field-decorator :field="field" :error="error" :success="success">
        <div class="week-picker">
            <div
                class="week-picker__month"
                v-for="month in field.roleAttributes.weekOptions"
                :key="month.title"
            >
                <div class="week-picker__month-label">
                    {{ month.title }}
                </div>
                <div class="week-picker__week week-picker__week--labels">
                    <span

                        v-for="day in days"
                        :key="day"
                        class="week-picker__day week-picker__day--no-border week-picker__day--muted muted"
                        v-translate
                    >
                        {{day}}
                    </span>
                </div>
                <div class="week-picker__weeks">

                    <label
                        :for="week[0].id"
                        v-for="week in month.weeks"
                        :key="month.title + '-' + getWeekId(week)"
                        class="week-picker__week"
                    >
                        <input
                                type="checkbox"
                                :value="getWeekId(week)"
                                :id="getWeekId(week)"
                                class="form__control form__control--checkbox"
                                v-model="value"
                                @focus="handleFocus"
                                @change="liveValidation"
                                @blur="$emit('change')"
                        />
                        <span class="checkmark checkmark--checkbox"></span>

                        <span
                            v-for="(day, key) in week"
                            :key="day.id + ' ' + key"
                            class="week-picker__day"
                            :class="{'week-picker__day--empty': !day.id || !day.day}"

                        >
                            {{day.day}}
                        </span>
                    </label>

                </div>
            </div>
        </div>

    </field-decorator>
</template>

<script>
    import FormElementMixin from '../mixins/FormElementMixin';

    export default {
        data() {
            return {
                days: ['maa', 'din', 'woe', 'don', 'vrij', "zat", 'zon']
            }
        },
        name: 'WeekPickerComponent',
        mixins: [FormElementMixin],
        computed: {
            value: {
                get() {
                    if (this.field.value === null) return [];
                    return this.field.value;
                },
                set(val) {
                    this.$emit('input', val);
                    this.$emit('change', val);
                }
            }
        },
        methods: {
            getWeekId(week) {
                let firstDayWithId= week.find(day => day.id !== "");
                return firstDayWithId.id
            }
        },
        created() {
            if (!Array.isArray(this.value)) this.value = [];
        }
    }
</script>