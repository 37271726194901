<script type="module">
import { mapState } from 'vuex';
import FieldDecorator from '../form-elements/FieldDecorator';

export default {
	data() {
		return {
			validated: false,
		}
	},
	computed: {
		value: {
			get() {
				return this.field.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		},
		...mapState({
			validationUrl: state => state.survey.validationAjaxBaseUrl,
			autocompleteUrl: state => state.survey.refValueAjaxBaseUrl,
			autocompleteResolve: state => state.survey.refValueLabelBaseUrl
		}),
		// Check if the question has an error, this can be because the validation in the element finds an error OR if the errormessage is filled in by the main call.
		error() {
			if (this.validated && this.field.errorMessage !== null) {
				return true;
			}

			return false;
		},
		success() {	
			if (this.validated && this.field.errorMessage === null) return true;
			return false;
		}
	},
	watch: { 
		"field.errorMessage": {
			handler(newVal, oldVal) { // watch it
				if (newVal !== oldVal) this.validated = true;
			},
			deep: true
		}
	},
	props: {
		field: {
			type: Object,
			required: true,
		}
	},
	methods: {
		// This uses serverside validation, includes a debounce
		async liveValidation() {
			this.field.errorMessage = null;
			try {
				const data = { [this.field.id]: this.field.value, step: this.$store.state.step.id };
				
				const result = await this.$post(this.validationUrl, data);
				this.field.errorMessage = result.data.errorMessage;
				this.validated = true;

                if(this.field.errorMessage !== null) {
                    this.$emit('error');
                } else {
                    this.$emit('change');
                }

			} catch (error) {
				console.log(error);
			}
		},
		// On blur we need to submit the answer but also validate the entry one last time.
		onBlur() {
			this.liveValidation();
			this.$emit('blur');
		},
		handleFocus() {
			this.$emit('focus');
		}
	},
	components: {
		FieldDecorator
	}
}
</script>
